import React from "react";
import "./FeaturesCards.css";
import EaseOfUse from "../../assets/EaseOfUse.svg";
import CompleteManagement from "../../assets/CompleteManagement.svg";
import EffectiveOrganization from "../../assets/EffectiveOrganization.svg";
import TechnicalSupport from "../../assets/TechnicalSupport.svg";

function FeaturesCards() {
  return (
    <div className="features-container">
      <div className="features-card">
        <img src={EaseOfUse} alt="Ease of use" className="card-icon" />

        <h4 className="features-card-h4">سهولة الاستخدام</h4>

        <p className="features-card-p">
          واجهة بسيطة وسهلة الاستخدام تتيح لك ولعملائك حجز المواعيد بكل يسر
        </p>
      </div>
      <div className="features-card">
        <img
          src={EffectiveOrganization}
          alt="Ease of use"
          className="card-icon"
        />

        <h4 className="features-card-h4">تنظيم فعال</h4>

        <p className="features-card-p">
          نظم مواعيدك وقلل من الازدحام والانتظار. احصل على إشعارات وتذكيرات
          تلقائية لمتابعة حجوزاتك.
        </p>
      </div>
      <div className="features-card">
        <img src={CompleteManagement} alt="Ease of use" className="card-icon" />

        <h4 className="features-card-h4">إدارة كاملة</h4>

        <p className="features-card-p">
          تحكم كامل في جدول مواعيدك مع إمكانية تعديل وإلغاء الحجوزات بسهولة.
        </p>
      </div>
      <div className="features-card">
        <img src={TechnicalSupport} alt="Ease of use" className="card-icon" />

        <h4 className="features-card-h4">دعم فني</h4>

        <p className="features-card-p">
          فريق دعم فني متواجد على مدار الساعة لمساعدتك في أي وقت.
        </p>
      </div>
    </div>
  );
}

export default FeaturesCards;
