import React, { useState, useEffect } from "react";
import AvailabilityCalendar from "./AvailabilityCalendar";
import {
  getMonthlyAvailabilities,
  addAvailabilitySpecificDay,
  deleteAvailability,
} from "../../../services/api";
import { useTranslation } from "react-i18next";
import moment from "moment";
import WeekdayAvailability from "./WeekdayAvailability";

const Availability = () => {
  const [availabilities, setAvailabilities] = useState([]);
  const [currentYear, setCurrentYear] = useState(moment().year());
  const [currentMonth, setCurrentMonth] = useState(moment().month() + 1); // month is 0-indexed
  const { t } = useTranslation();
  const jwtToken = localStorage.getItem("token");

  const fetchMonthlyAvailabilities = async (year, month) => {
    const data = { year, month };
    const availabilities = await getMonthlyAvailabilities(data, jwtToken);
    setAvailabilities(availabilities);
  };

  useEffect(() => {
    fetchMonthlyAvailabilities(currentYear, currentMonth);
  }, [currentYear, currentMonth]);

  const handleEditEvent = async (event) => {
    await addAvailabilitySpecificDay(event, jwtToken);
    fetchMonthlyAvailabilities(currentYear, currentMonth);
  };

  const handleDeleteEvent = async (event) => {
    if (event.isSpecificDay) {
      await deleteAvailability(event.date.format("YYYY-MM-DD"), jwtToken);
    } else {
      const data = {
        date: event.date.format("YYYY-MM-DD"),
        startTime: "00:00:00",
        endTime: "00:00:00",
        isOff: true,
      };
      await addAvailabilitySpecificDay(data, jwtToken);
    }
    fetchMonthlyAvailabilities(currentYear, currentMonth);
  };

  const handleNavigate = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    setCurrentYear(year);
    setCurrentMonth(month);
    fetchMonthlyAvailabilities(year, month);
  };

  const handleWeeklyAvailabilitySubmit = () => {
    fetchMonthlyAvailabilities(currentYear, currentMonth);
  };

  return (
    <div style={{ padding: "0 2" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "15 5",
        }}
      >
        <div>
          <h1 style={{ margin: "0 0 10px 0" }}>{t("Availability")}</h1>
        </div>
      </div>
      <div>
        <WeekdayAvailability onSubmit={handleWeeklyAvailabilitySubmit} />
      </div>

      <AvailabilityCalendar
        events={availabilities}
        onEditEvent={handleEditEvent}
        onDeleteEvent={handleDeleteEvent}
        fetchMonthlyAvailabilities={fetchMonthlyAvailabilities}
        handleNavigate={handleNavigate}
      />
    </div>
  );
};

export default Availability;
