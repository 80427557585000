import React, { useState } from "react";
import { Empty, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import "./ClientAppointments.css"; // Import the CSS file

function ClientAppointments({ appointments }) {
  const [activeTab, setActiveTab] = useState("upcoming");
  const { t } = useTranslation();

  const now = new Date();

  const combineDateTime = (date, time) => new Date(`${date} ${time}`);

  const upcomingAppointments = appointments.filter(
    (appointment) =>
      combineDateTime(
        appointment.bookingDate.substring(0, 10),
        appointment.startTime
      ) > now
  );
  const previousAppointments = appointments.filter(
    (appointment) =>
      combineDateTime(
        appointment.bookingDate.substring(0, 10),
        appointment.startTime
      ) <= now
  );

  if (appointments.length === 0) {
    return <Empty description={t("No appointments available")} />;
  }

  const items = [
    {
      key: "upcoming",
      label: t("upcomingReservations"),
      children:
        upcomingAppointments.length === 0 ? (
          <Empty description={t("No upcoming appointments")} />
        ) : (
          <div>
            {upcomingAppointments.map((appointment) => (
              <div
                key={appointment.id}
                className="upcoming-previous-reservations"
                style={{
                  borderRight: `2px solid rgb(${appointment.service.color})`,
                  direction: "rtl",
                }}
              >
                <div>{appointment.bookingDate.substring(0, 10)}</div>
                <div>{appointment.startTime}</div>
                <div>{appointment.service.name}</div>
              </div>
            ))}
          </div>
        ),
    },
    {
      key: "previous",
      label: t("Previous reservations"),
      children:
        previousAppointments.length === 0 ? (
          <Empty description={t("No previous appointments")} />
        ) : (
          <div>
            {previousAppointments.map((appointment) => (
              <div
                key={appointment.id}
                className="upcoming-previous-reservations"
                style={{
                  borderRight: `2px solid rgb(${appointment.service.color})`,
                }}
              >
                <div>{appointment.bookingDate.substring(0, 10)}</div>
                <div>{appointment.startTime}</div>
                <div>{appointment.service.name}</div>
              </div>
            ))}
          </div>
        ),
    },
  ];

  return (
    <div style={{ direction: "rtl" }}>
      <Tabs
        activeKey={activeTab}
        onChange={setActiveTab}
        items={items}
        className="custom-tabs" // Apply the custom CSS class
      />
    </div>
  );
}

export default ClientAppointments;
