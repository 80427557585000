import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Register.css";
import RegisterForm from "./RegisterForm";
import { ReactComponent as DarkBoxSvg } from "../../assets/DarkBox.svg";
import { ReactComponent as LightBoxSvg } from "../../assets/LightBox.svg";

const Register = () => {
  const navigate = useNavigate();
  const [redirected, setRedirected] = useState(false); // State to track redirection

  const handleRegisterSuccess = (token) => {
    if (!redirected) {
      // localStorage.setItem("token", token);
      // navigate("/login");
      // setRedirected(true); // Set redirected to true
    }
  };

  return (
    <div className="Login">
      <div
        className="Register-box"
        style={{
          direction: "rtl",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "3vh",
        }}
      >
        <RegisterForm onRegisterSuccess={handleRegisterSuccess} />
      </div>
      <div className="top-left-dark">
        <DarkBoxSvg />
      </div>
      <div className="top-left-light">
        <LightBoxSvg />
      </div>
      <div className="bottom-right-dark">
        <DarkBoxSvg />
      </div>
      <div className="bottom-right-light">
        <LightBoxSvg />
      </div>
    </div>
  );
};

export default Register;
