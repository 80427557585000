import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./TermsAndConditions.css";

function TermsAndConditions() {
  return (
    <div>
      <div className="background-img-terms">
        <div className="header-container">
          <Header />
        </div>
      </div>
      <div className="terms-container">
        <h1 style={{ fontSize: 40 }}>الشروط والأحكام لموقع بوكلي</h1>
        <p>مرحبًا بكم في بوكلي</p>
        <p>
          هذه الشروط والأحكام تحدد القواعد والإرشادات لاستخدام موقع بوكلي.
          باستخدام هذا الموقع، فإنك توافق على الالتزام بهذه الشروط والأحكام.
        </p>
        <h2>1. التعريفات</h2>
        <p>"الموقع": يُقصد به موقع بوكلي.</p>
        <p>"المستخدم": يُقصد به أي شخص يستخدم الموقع.</p>
        <p>
          "المحتوى": يشمل النصوص، الصور، الفيديوهات، والمواد الأخرى المتاحة على
          الموقع.
        </p>
        <h2>2. استخدام الموقع</h2>
        <p>
          يُسمح للمستخدمين بتصفح المحتوى المتاح على الموقع واستخدامه لأغراض
          شخصية وغير تجارية.
        </p>
        <p>
          يُمنع نسخ أو إعادة نشر أو توزيع أو استخدام المحتوى لأي غرض تجاري دون
          إذن كتابي مسبق من بوكلي.
        </p>
        <h2>3. حساب المستخدم</h2>
        <p>قد يتطلب بعض أجزاء الموقع إنشاء حساب مستخدم.</p>
        <p>يجب على المستخدمين تقديم معلومات دقيقة وكاملة عند تسجيل الحساب.</p>
        <p>
          يتحمل المستخدم مسؤولية الحفاظ على سرية معلومات تسجيل الدخول الخاصة به.
        </p>
        <h2>4. الخصوصية</h2>
        <p>نحن نحترم خصوصية المستخدمين ونلتزم بحماية بياناتهم الشخصية.</p>
        <p>لمزيد من المعلومات، يُرجى مراجعة سياسة الخصوصية الخاصة بنا.</p>
        <h2>5. حقوق الملكية الفكرية</h2>
        <p>
          جميع المحتويات والمواد المتاحة على الموقع محمية بموجب حقوق الطبع
          والنشر والقوانين الأخرى ذات الصلة.
        </p>
        <p>يُحظر استخدام أي جزء من الموقع بشكل ينتهك حقوق الملكية الفكرية.</p>
        <h2>6. سلوك المستخدم</h2>
        <p>
          يُحظر نشر أي محتوى غير قانوني أو مضر أو مخل بالآداب العامة على الموقع.
        </p>
        <p>يحتفظ بوكلي بالحق في إزالة أي محتوى يخالف هذه الشروط والأحكام.</p>
        <h2>7. التعديلات على الشروط</h2>
        <p>يحتفظ بوكلي بالحق في تعديل هذه الشروط والأحكام في أي وقت.</p>
        <p>
          سيتم إشعار المستخدمين بأي تعديلات جديدة عن طريق نشر النسخة المحدثة على
          الموقع.
        </p>
        <h2>8. حدود المسؤولية</h2>
        <p>
          يُخلي بوكلي مسؤوليته عن أي أضرار ناتجة عن استخدام الموقع أو عدم القدرة
          على استخدامه.
        </p>
        <p>يُقدم الموقع "كما هو" دون أي ضمانات، سواء كانت صريحة أو ضمنية.</p>
        <h2>9. القانون الواجب التطبيق</h2>
        <p>تخضع هذه الشروط والأحكام وتُفسر وفقًا لقوانين الدولة.</p>
        <h2>10. اتصل بنا</h2>
        <p>
          إذا كانت لديك أي استفسارات أو تعليقات حول هذه الشروط والأحكام، يُرجى
          الاتصال بنا على [admin@bookly.sa]
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default TermsAndConditions;
