import React from "react";
import { Modal } from "antd";
import Meeting from "./Meeting";

const MainModal = ({
  isVisible,
  onClose,
  modalContent,
  handleMeetingClick,
  modalPosition,
}) => (
  <Modal
    title="المواعيد"
    open={isVisible}
    onCancel={onClose}
    footer={null}
    zIndex={1000}
    style={{
      top: modalPosition.top,
      left: modalPosition.left,
      right: modalPosition.right,
      position: "absolute",
      direction: "rtl",
    }}
    className="main-modal"
  >
    <div style={{ maxHeight: "180px", overflowY: "auto" }}>
      {modalContent.map((meeting, index) => (
        <Meeting
          key={index}
          meeting={meeting}
          onClick={(e) => handleMeetingClick(meeting, e)}
        />
      ))}
    </div>
  </Modal>
);

export default MainModal;
