import React, { useState } from "react";
import { Modal, Form, Input, Button } from "antd";
import { useTranslation } from "react-i18next";
import { addUser } from "../../../services/api";

const AddClient = ({ isVisible, handleOk, handleCancel }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const onFinish = async (values) => {
    try {
      const jwtToken = localStorage.getItem("token");

      const response = await addUser(values, jwtToken);
      handleOk();
    } catch (error) {
      console.error("Error adding client:", error);
    }
  };

  return (
    <Modal
      title={t("Add New Client")}
      open={isVisible}
      onCancel={handleCancel}
      footer={null}
      style={{ direction: "rtl" }}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="firstName"
          label={t("FirstName")}
          rules={[
            { required: true, message: t("Please input the first name!") },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="lastName"
          label={t("LastName")}
          rules={[
            { required: true, message: t("Please input the last name!") },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label={t("Email")}
          rules={[{ required: true, message: t("Please input the email!") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="Phone"
          label={t("Phone")}
          rules={[
            { required: true, message: t("Please input the phone number!") },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t("Add Client")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddClient;
