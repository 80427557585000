import React from "react";

const CustomToolbar = (toolbar) => {
  const goToBack = () => {
    toolbar.onNavigate("PREV");
  };

  const goToNext = () => {
    toolbar.onNavigate("NEXT");
  };

  const goToCurrent = () => {
    toolbar.onNavigate("TODAY");
  };

  const label = () => {
    const date = toolbar.date;
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    return (
      <span>
        {month} {year}
      </span>
    );
  };

  return (
    <div className="rbc-toolbar" style={{ direction: "rtl" }}>
      <span className="rbc-btn-group">
        <button type="button" onClick={goToBack}>
          الشهر السابق
        </button>
        <button type="button" onClick={goToCurrent}>
          الشهر الحالي
        </button>
        <button type="button" onClick={goToNext}>
          الشهر التالي
        </button>
      </span>
      <span className="rbc-toolbar-label">{label()}</span>
    </div>
  );
};

export default CustomToolbar;
