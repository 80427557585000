import React from "react";
import { Menu, Modal } from "antd";
import {
  CalendarOutlined,
  SettingOutlined,
  CustomerServiceOutlined,
  LogoutOutlined,
  UsergroupAddOutlined,
  FieldTimeOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { confirm } = Modal;

const MenuList = ({ darkTheme, handleMenuClick }) => {
  const { t } = useTranslation();

  const menuItems = [
    {
      key: "calendar",
      icon: <CalendarOutlined />,
      label: t("calendar"),
    },
    {
      key: "availability",
      icon: <FieldTimeOutlined />,
      label: t("availability"),
    },
    {
      key: "services",
      icon: <CustomerServiceOutlined />,
      label: t("services"),
    },
    {
      key: "clients",
      icon: <UsergroupAddOutlined />,
      label: t("Clients"),
    },
    // {
    //   key: "settings",
    //   icon: <SettingOutlined />,
    //   label: t("settings"),
    //   style: { marginTop: "30vh" },
    // },
    {
      key: "logout",
      icon: <LogoutOutlined />,
      label: t("log out"),
      style: { marginTop: "auto" },
    },
  ];

  const showLogoutConfirm = () => {
    confirm({
      direction: "rtl",
      title: t("Are you sure you want to log out?"),
      okText: t("Yes"),
      okType: "danger",
      cancelText: t("No"),
      onOk() {
        localStorage.removeItem("token");
        window.location.href = "/login";
      },
    });
  };

  const handleClick = (e) => {
    if (e.key === "logout") {
      showLogoutConfirm();
    } else {
      handleMenuClick(e);
    }
  };

  return (
    <div>
      <Menu
        theme={darkTheme ? "light" : "dark"}
        mode="inline"
        items={menuItems}
        className="menu-bar"
        onClick={handleClick}
      />
    </div>
  );
};

export default MenuList;
