import React, { useState, useEffect } from "react";
import { Button, notification, Row, Col, Input } from "antd";
import {
  addAvailabilityWeekly,
  getAvailabilityWeekly,
} from "../../../services/api"; // Import your API function

const WeekdayAvailability = ({ onSubmit }) => {
  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const initialAvailability = weekdays.map((day) => ({
    dayOfWeek: day,
    startTime: "00:00",
    endTime: "00:00",
    isOff: true,
  }));

  const [availability, setAvailability] = useState(initialAvailability);
  const [isFormValid, setIsFormValid] = useState(false);
  const jwtToken = localStorage.getItem("token");

  useEffect(() => {
    const fetchAvailability = async () => {
      try {
        const response = await getAvailabilityWeekly(jwtToken);
        if (response && Array.isArray(response)) {
          const updatedAvailability = response.map((day) => ({
            dayOfWeek: day.dayOfWeek,
            startTime: day.startTime ? day.startTime.slice(0, 5) : "00:00",
            endTime: day.endTime ? day.endTime.slice(0, 5) : "00:00",
            isOff: day.isOff ?? true,
          }));
          setAvailability((prevAvailability) =>
            prevAvailability.map(
              (day) =>
                updatedAvailability.find(
                  (d) => d.dayOfWeek === day.dayOfWeek
                ) || day
            )
          );
        }
      } catch (error) {
        notification.error({
          message: "Error",
          description: "An error occurred while fetching the availability data",
        });
      }
    };

    fetchAvailability();
  }, [jwtToken]);

  useEffect(() => {
    checkFormValidity();
  }, [availability]);

  const handleTimeChange = (e, dayOfWeek, timeType) => {
    const time = e.target.value;
    setAvailability((prevAvailability) =>
      prevAvailability.map((day) =>
        day.dayOfWeek === dayOfWeek
          ? {
              ...day,
              [timeType]: time,
              isOff: false,
            }
          : day
      )
    );
  };

  const handleBlur = (e, dayOfWeek, timeType) => {
    const time = e.target.value;
    const timePattern = /^([01]\d|2[0-3]):([0-5]\d)$/;

    if (time === "" || timePattern.test(time)) {
      setAvailability((prevAvailability) =>
        prevAvailability.map((day) => {
          if (day.dayOfWeek === dayOfWeek) {
            if (
              timeType === "endTime" &&
              time < day.startTime &&
              !(time === "00:00" && day.startTime === "00:00")
            ) {
              notification.error({
                message: "Invalid Time",
                description: "End time cannot be earlier than start time.",
              });
              return { ...day, [timeType]: "00:00" };
            } else if (
              timeType === "endTime" &&
              time === day.startTime &&
              time !== "00:00"
            ) {
              return { ...day, [timeType]: "00:00" };
            }
            return {
              ...day,
              [timeType]: time || "00:00",
            };
          }
          return day;
        })
      );
    } else {
      notification.error({
        message: "Invalid Time Format",
        description: "Please enter the time in HH:mm format.",
      });
      // Reset to previous valid time
      setAvailability((prevAvailability) =>
        prevAvailability.map((day) =>
          day.dayOfWeek === dayOfWeek
            ? {
                ...day,
                [timeType]: "00:00",
              }
            : day
        )
      );
    }
  };

  const checkFormValidity = () => {
    const isValid = availability.every(
      (day) =>
        (day.endTime > day.startTime && day.endTime !== "00:00") ||
        (day.startTime === "00:00" && day.endTime === "00:00")
    );
    setIsFormValid(isValid);
  };

  const handleSubmitAll = async () => {
    const data = availability.map((day) => {
      const isOff =
        !day.startTime ||
        !day.endTime ||
        (day.startTime === "00:00" && day.endTime === "00:00");
      return {
        dayOfWeek: day.dayOfWeek,
        startTime: day.startTime ? `${day.startTime}:00` : "00:00:00",
        endTime: day.endTime ? `${day.endTime}:00` : "00:00:00",
        isOff: isOff,
      };
    });

    try {
      await addAvailabilityWeekly(data, jwtToken);
      notification.success({
        message: "Success",
        description: "Weekly availability submitted successfully",
      });
      onSubmit(); // Notify parent component to refresh availability data
    } catch (error) {
      notification.error({
        message: "Error",
        description: "An error occurred while submitting the availability",
      });
    }
  };

  return (
    <div style={{ direction: "ltr" }}>
      <Row gutter={16} justify="center" align="middle">
        {availability.map((day) => (
          <Col
            key={day.dayOfWeek}
            span={3}
            style={{
              textAlign: "center",
              padding: "16px",
              border: "1px solid #ddd",
              margin: "4px",
            }}
          >
            <div style={{ margin: "0 0 10px 0" }}>
              <strong>{day.dayOfWeek}</strong>
            </div>
            <Input
              value={day.startTime}
              onChange={(e) => handleTimeChange(e, day.dayOfWeek, "startTime")}
              onBlur={(e) => handleBlur(e, day.dayOfWeek, "startTime")}
              placeholder="HH:mm"
              style={{ marginBottom: 8, textAlign: "center" }}
            />
            <Input
              value={day.endTime}
              onChange={(e) => handleTimeChange(e, day.dayOfWeek, "endTime")}
              onBlur={(e) => handleBlur(e, day.dayOfWeek, "endTime")}
              placeholder="HH:mm"
              style={{ marginBottom: 8, textAlign: "center" }}
            />
          </Col>
        ))}
      </Row>
      <div style={{ textAlign: "center", marginTop: "16px" }}>
        <Button
          type="primary"
          onClick={handleSubmitAll}
          disabled={!isFormValid}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default WeekdayAvailability;
