import React, { useState, useRef } from "react";

const OTPInput = ({ length, onChange }) => {
  const [otp, setOtp] = useState(Array(length).fill(""));
  const inputRefs = useRef([]);

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const otpArray = [...otp];
    otpArray[index] = value;

    setOtp(otpArray);
    onChange(otpArray.join(""));

    if (value.length === 1 && index < length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handlePaste = (e) => {
    const paste = e.clipboardData.getData("text").slice(0, length).split("");
    const otpArray = [...otp];
    paste.forEach((char, i) => {
      if (i < length) {
        otpArray[i] = char;
      }
    });

    setOtp(otpArray);
    onChange(otpArray.join(""));

    // Focus the next empty input after paste
    const nextIndex = paste.length >= length ? length - 1 : paste.length;
    inputRefs.current[nextIndex]?.focus();
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        gap: "10px",
        direction: "ltr",
      }}
    >
      {Array.from({ length }).map((_, index) => (
        <input
          key={index}
          type="text"
          id={`otp-input-${index}`}
          maxLength="1"
          value={otp[index]}
          ref={(el) => (inputRefs.current[index] = el)}
          onChange={(e) => handleInputChange(e, index)}
          onPaste={handlePaste}
          style={{
            width: "40px",
            height: "40px",
            textAlign: "center",
          }}
        />
      ))}
    </div>
  );
};

export default OTPInput;
