import React, { useState, useEffect } from "react";
import { Button, Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import {
  getCategories,
  getServices,
  addCategory,
  addService,
} from "../../../services/api";
import Category from "./Category";
import AddCategoryModal from "./AddCategoryModal";
import AddServiceModal from "./AddServiceModal";
import { useTranslation } from "react-i18next";

const CategoryServicePage = () => {
  const [categories, setCategories] = useState([]);
  const [services, setServices] = useState([]);
  const [isCategoryModalVisible, setIsCategoryModalVisible] = useState(false);
  const [isServiceModalVisible, setIsServiceModalVisible] = useState(false);
  const { t } = useTranslation();

  const fetchCategoriesAndServices = async () => {
    try {
      const jwtToken = localStorage.getItem("token");
      const categoriesResponse = await getCategories(jwtToken);
      setCategories(categoriesResponse);

      const servicesResponse = await getServices(jwtToken);
      setServices(servicesResponse);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchCategoriesAndServices();
  }, []);

  const handleAddCategory = async (values) => {
    try {
      const jwtToken = localStorage.getItem("token");
      const categoryData = { ...values };
      const newCategory = await addCategory(categoryData, jwtToken);
      setCategories((prevCategories) => [...prevCategories, newCategory]);
      setIsCategoryModalVisible(false);

      // Fetch updated services after adding a new category
      fetchCategoriesAndServices();
    } catch (error) {
      console.error("Error adding category:", error);
    }
  };

  const handleAddService = async (values) => {
    console.log("Adding service:", values);
    try {
      const jwtToken = localStorage.getItem("token");
      const serviceData = { ...values, color: values.color };
      const newService = await addService(serviceData, jwtToken);
      setServices((prevServices) => [...prevServices, newService]);
      setIsServiceModalVisible(false);
    } catch (error) {
      console.error("Error adding service:", error);
    }
  };

  const items = [
    {
      key: "addCategory",
      label: (
        <div onClick={() => setIsCategoryModalVisible(true)}>
          {t("categoryServicePage.addCategoryLabel")}
        </div>
      ),
    },
    {
      key: "addService",
      label: (
        <div onClick={() => setIsServiceModalVisible(true)}>
          {t("categoryServicePage.addServiceLabel")}
        </div>
      ),
    },
  ];

  return (
    <div style={{ direction: "rtl", margin: "20px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "2px 12px",
        }}
      >
        <h2>{t("categoryServicePage.categoriesTitle")}</h2>
        <div style={{ textAlign: "right" }}>
          <Dropdown menu={{ items }} placement="bottomLeft">
            <Button>{t("categoryServicePage.add")}</Button>
          </Dropdown>
        </div>
      </div>

      <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
        {categories.map((category) => (
          <Category key={category.id} category={category} services={services} />
        ))}
      </div>

      <AddCategoryModal
        visible={isCategoryModalVisible}
        onCancel={() => setIsCategoryModalVisible(false)}
        onFinish={handleAddCategory}
      />

      <AddServiceModal
        visible={isServiceModalVisible}
        onCancel={() => setIsServiceModalVisible(false)}
        onFinish={handleAddService}
        categories={categories} // Pass categories to AddServiceModal
      />
    </div>
  );
};

export default CategoryServicePage;
