import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Button,
  DatePicker,
  Select,
  ConfigProvider,
  message,
} from "antd";
import { useTranslation } from "react-i18next";
import "moment/locale/ar";
import moment from "moment";
import {
  getServices,
  getUsers,
  createBooking,
  getAvailableSlots,
} from "../../../services/api";

const { Option } = Select;

function NewAppointment({
  visible,
  onClose,
  onSubmit,
  handleCreateAppointment,
  handleUpdateAppointment,
  handleDeleteAppointment,
  selectedDate,
  tenantID,
}) {
  const { t, i18n } = useTranslation();
  const dateFormat = "YYYY/MM/DD";

  const [services, setServices] = useState([]);
  const [users, setUsers] = useState([]);
  const [slots, setSlots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState(null);

  const [form] = Form.useForm();
  const isRTL = i18n.language === "ar";

  useEffect(() => {
    fetchServices();
    fetchUsers();
    if (selectedDate) {
      form.setFieldsValue({ date: moment(selectedDate) });
    }
  }, [selectedDate]);

  const fetchServices = async () => {
    try {
      const jwtToken = localStorage.getItem("token");
      const servicesResponse = await getServices(jwtToken);
      setServices(servicesResponse);
    } catch (error) {
      console.error("Error fetching services:", error);
      message.error(t("Error fetching services"));
    }
  };

  const fetchUsers = async () => {
    try {
      const jwtToken = localStorage.getItem("token");
      const data = {
        searchName: "",
        PageNumber: 1,
        PageSize: 50,
      };

      const usersResponse = await getUsers(data, jwtToken);
      setUsers(usersResponse.data);
    } catch (error) {
      console.error("Error fetching users:", error);
      message.error(t("Error fetching users"));
    }
  };

  const fetchAvailableSlots = async (serviceId, date) => {
    if (!serviceId || !date) return;

    try {
      console.log("selectedDate:", selectedDate);
      const date = new Date(selectedDate);
      const startDate = moment(date).format("YYYY/MM/DD");
      console.log("startDate:", startDate);

      const data = {
        serviceId,
        startDate: startDate,
        endDate: startDate,
      };

      const slotsResponse = await getAvailableSlots(data, tenantID);
      console.log("Available slots response:", slotsResponse);
      if (slotsResponse.length > 0 && slotsResponse[0].availableTimes) {
        setSlots(slotsResponse[0].availableTimes);
        // when you get new response clear  name="time"
        form.setFieldsValue({ time: null });
      } else {
        setSlots([]);
      }
    } catch (error) {
      console.error("Error fetching slots:", error);
      message.error(t("Error fetching slots"));
    }
  };

  useEffect(() => {
    if (selectedServiceId) {
      fetchAvailableSlots(selectedServiceId, selectedDate);
    }
  }, [selectedServiceId, selectedDate]);

  const handleServiceChange = (value) => {
    const service = services.find((service) => service.name === value);
    setSelectedServiceId(service ? service.id : null);
    console.log("Selected Service ID: ", service ? service.id : null);
    form.setFieldsValue({ time: null });
  };

  // const handleDateChange = (date) => {
  //   console.log("Selected Date: ", date);
  //   fetchAvailableSlots(selectedServiceId, date);
  // };

  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        setLoading(true);
        const selectedService = services.find(
          (service) => service.name === values.service
        );
        const selectedUser = users.find(
          (user) => user.phoneNumber === values.client
        );

        console.log(
          "handleSubmit --- > ",
          values.date.format("YYYY-MM-DDTHH:mm:ss")
        );
        const appointment = {
          user: {
            firstName: selectedUser ? selectedUser.firstName : "",
            lastName: selectedUser ? selectedUser.lastName : "",
            email: selectedUser ? selectedUser.email : "",
            phoneNumber: selectedUser ? selectedUser.phoneNumber : "",
          },
          serviceId: selectedService ? selectedService.id : null,
          bookingDate: values.date.format("YYYY-MM-DDTHH:mm:ss"),
          startTime: values.time,
        };

        try {
          const created = await createBooking(appointment);
          handleCreateAppointment(created);
          onSubmit();
        } catch (error) {
          console.error("Error creating appointment:", error);
          message.error(t("Error creating appointment"));
        } finally {
          setLoading(false);
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <ConfigProvider direction={isRTL ? "rtl" : "ltr"}>
      <Modal
        open={visible}
        title={t("New Appointment")}
        onCancel={onClose}
        footer={null}
        className="new-appointment-modal"
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label={t("Service")}
            name="service"
            rules={[{ required: true, message: t("Please select a service") }]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder={t("Select a service")}
              onChange={handleServiceChange}
            >
              {services.map((service) => (
                <Option
                  key={service.id}
                  value={service.name}
                  style={{ color: `#1A1F23` }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p
                      style={{
                        background: `rgb(${service.color})`,
                        width: "15px",
                        height: "15px",
                        borderRadius: "50%",
                        marginLeft: "10px",
                      }}
                    ></p>
                    <p>{service.name}</p>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={t("Date")}
            name="date"
            rules={[{ required: true, message: t("Please select the date") }]}
          >
            <DatePicker
              style={{ width: "100%" }}
              format={dateFormat}
              defaultValue={selectedDate ? moment(selectedDate) : null}
              disabled
              //onChange={handleDateChange}
            />
          </Form.Item>
          <Form.Item
            label={t("Time")}
            name="time"
            rules={[{ required: true, message: t("Please select the time") }]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder={t("Select a time")}
              disabled={!slots.length}
            >
              {slots.map((slot) => (
                <Option key={slot} value={slot}>
                  {slot}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={t("Client")}
            name="client"
            rules={[{ required: true, message: t("Please select a client") }]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder={t("Select a client")}
            >
              {users.map((user) => (
                <Option key={user.phoneNumber} value={user.phoneNumber}>
                  {user.firstName + " " + user.lastName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              onClick={handleSubmit}
              loading={loading}
            >
              {t("Submit")}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </ConfigProvider>
  );
}

export default NewAppointment;
