import React from "react";
import { Empty } from "antd"; // Import Ant Design's Empty component
import Service from "./Service";

const Category = ({ category, services }) => {
  const filteredServices = services.filter(
    (service) => service.categoryId === category.id
  );

  return (
    <div
      key={category.id}
      style={{
        backgroundColor: "white",
        direction: "rtl",
        padding: "20px",
        margin: "10px",
        border: "1px solid #ddd",
        borderRadius: "8px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        width: "76vh",
      }}
    >
      <div
        style={{
          padding: "10px",
          backgroundColor: "#f5f5f5",
          borderRadius: "8px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <strong>{category.name}</strong>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          marginTop: "10px",
        }}
      >
        {filteredServices.length > 0 ? (
          filteredServices.map((service) => (
            <Service key={service.id} service={service} />
          ))
        ) : (
          <Empty
            description="لا توجد خدمات"
            imageStyle={{
              height: 50,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }} // Adjust the height as needed
          />
        )}
      </div>
    </div>
  );
};

export default Category;
