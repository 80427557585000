import React from "react";
import AccessibleIcon from "./AccessibleIcon"; // Adjust the import path as needed
import "./FeatureItem.css";

const FeatureItem = ({ feature, theme }) => (
  <div className="feature-item">
    <span
      className={
        theme === "dark" ? "feature-item-text-dark" : "feature-item-text-light"
      }
    >
      {feature.name}
    </span>
    <AccessibleIcon theme={theme} className="feature-item-icon" />
  </div>
);

export default FeatureItem;
