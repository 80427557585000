import React from "react";
import "./Footer.css";
import icon from "../../assets/icon.svg";
import { useTranslation } from "react-i18next";
import TermsAndConditions from "./TermsAndConditions";
import { useNavigate } from "react-router-dom";
import { Link } from "react-scroll";

function Footer() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <div className="footer-container">
      <div className="footer-div">
        <img src={icon} alt="icon" className="footer-icon" />
        <p>{t("footer.title")}</p>
      </div>
      <div className="footer-div">
        <Link onClick={() => handleNavigate("/TermsAndConditions")}>
          الشروط والاحكام وسياسية الخصوصية
        </Link>
      </div>
    </div>
  );
}

export default Footer;
