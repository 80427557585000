import React from "react";

const AccessibleIcon = ({ theme }) => {
  if (theme === "dark") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
      >
        <circle cx="10" cy="10.5" r="10" fill="white" />
        <path
          d="M6.25 11.125L9.25 13L13.75 8"
          stroke="#152C5B"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
      >
        <circle cx="10" cy="10.5" r="10" fill="#152C5B" />
        <path
          d="M6.25 11.125L9.25 13L13.75 8"
          stroke="white"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
};

export default AccessibleIcon;
