import React from "react";

const Meeting = ({ meeting, onClick }) => (
  <div
    style={{
      color: "#1A1F23",
      textAlign: "center",
      display: "flex",
      justifyContent: "space-between",
      padding: "4px",
      margin: "2px",
      cursor: "pointer",
      borderRight: `3px solid rgb(${meeting.service.color})`,
      background: `linear-gradient(rgba(${meeting.service.color}, 0.15), rgba(${meeting.service.color}, 0.15)),linear-gradient(white, white)`,
    }}
    onClick={onClick}
  >
    <div style={{ direction: "rtl" }}>
      {meeting.user.firstName} &nbsp; {meeting.user.lastName}
    </div>
    <div style={{ marginLeft: "8px" }}>{meeting.startTime}</div>
  </div>
);

export default Meeting;
