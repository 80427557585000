import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  notification,
  message,
  Select,
} from "antd";
import {
  UserOutlined,
  LockOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import {
  register,
  getExists,
  validateRegistration,
  getTypeList,
} from "../../services/api";
import icon from "../../assets/icon.svg";
import OTPForm from "./OTPForm";
import "./Register.css";

const RegisterForm = ({ onRegisterSuccess }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState({});
  const [email, setEmail] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [availabilityMessage, setAvailabilityMessage] = useState("");
  const [step, setStep] = useState(1);
  const [typeList, setTypeList] = useState([]);

  useEffect(() => {
    const fetchTypeList = async () => {
      try {
        const data = await getTypeList();
        setTypeList(data);
      } catch (error) {
        console.error("Error fetching type list:", error);
      }
    };
    fetchTypeList();
  }, []);

  const validateTenantID = async (_, value) => {
    if (!value) {
      setAvailabilityMessage("");
      return Promise.reject();
    }
    try {
      const response = await getExists(value);
      if (response.exists) {
        setAvailabilityMessage(t("register.tenantIdTaken"));
        return Promise.reject(new Error(t("")));
      } else {
        setAvailabilityMessage(t("register.tenantIdAvailable"));
        return Promise.resolve();
      }
    } catch (error) {
      setAvailabilityMessage("");
      return Promise.reject(new Error(t("register.tenantCheckError")));
    }
  };

  const onTenantIDChange = (e) => {
    const { value } = e.target;
    form.setFieldsValue({ tenantID: value });
    if (value === "") setAvailabilityMessage("");
  };

  const onPhoneChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, "");
    if (numericValue.length <= 9) form.setFieldsValue({ phone: numericValue });
  };

  const onFinishStep1 = async (values) => {
    setLoading(true);
    try {
      await validateRegistration(values.phone, values.email);
      setFormValues(values);
      setStep(2); // Advance to next step only if validation succeeds
    } catch (error) {
      message.error(error.message);
      setStep(1); // Stay on the current step if registration validation fails
    } finally {
      setLoading(false);
    }
  };

  const onFinishStep2 = async (values) => {
    setLoading(true);
    setTenantId(values.tenantID);
    const requestData = { ...formValues, ...values };
    try {
      const response = await register(requestData);
      setEmail(formValues.email);
      setStep(3);

      if (onRegisterSuccess) {
        onRegisterSuccess(response.token);
      }
    } catch (error) {
      notification.error({
        message: t("register.registrationFailed"),
        description: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const validatePassword = (_, value) => {
    const passwordErrors = [];
    if (!value) passwordErrors.push(t("register.enterPassword"));
    if (value && value.length < 6)
      passwordErrors.push(t("register.passwordTooShort"));
    if (!/[A-Z]/.test(value))
      passwordErrors.push(t("register.passwordRequiresUpper"));
    if (!/[a-z]/.test(value))
      passwordErrors.push(t("register.passwordRequiresLower"));
    if (!/[0-9]/.test(value))
      passwordErrors.push(t("register.passwordRequiresDigit"));
    if (!/[^a-zA-Z0-9]/.test(value))
      passwordErrors.push(t("register.passwordRequiresNonAlphanumeric"));

    return passwordErrors.length
      ? Promise.reject(
          <div>
            {passwordErrors.map((err, i) => (
              <div key={i}>{err}</div>
            ))}
          </div>
        )
      : Promise.resolve();
  };

  const renderStep1 = () => (
    <Form form={form} name="registerForm" onFinish={onFinishStep1} size="large">
      <Form.Item
        name="firstName"
        rules={[{ required: true, message: t("register.enterFirstName") }]}
      >
        <Input
          prefix={<UserOutlined />}
          placeholder={t("register.firstName")}
        />
      </Form.Item>
      <Form.Item
        name="lastName"
        rules={[{ required: true, message: t("register.enterLastName") }]}
      >
        <Input prefix={<UserOutlined />} placeholder={t("register.lastName")} />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[
          { required: true, message: t("register.enterEmail") },
          { type: "email", message: t("register.invalidEmailFormat") },
        ]}
      >
        <Input prefix={<MailOutlined />} placeholder={t("register.email")} />
      </Form.Item>
      <Form.Item name="password" rules={[{ validator: validatePassword }]}>
        <Input.Password
          prefix={<LockOutlined />}
          placeholder={t("register.password")}
        />
      </Form.Item>
      <Form.Item
        name="phone"
        rules={[{ required: true, message: t("register.enterPhoneNumber") }]}
      >
        <Input
          prefix={<PhoneOutlined />}
          placeholder={t("register.phone")}
          maxLength={10}
          onChange={onPhoneChange}
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading} block>
          {t("register.next")}
        </Button>
      </Form.Item>
    </Form>
  );
  const renderStep2 = () => (
    <Form form={form} name="registerForm" onFinish={onFinishStep2} size="large">
      <Form.Item
        name="name"
        rules={[{ required: true, message: t("register.enterProjectName") }]}
      >
        <Input
          prefix={<UserOutlined />}
          placeholder={t("register.projectName")}
        />
      </Form.Item>
      <Form.Item
        name="tenantID"
        rules={[
          { required: true, message: t("register.entertenantID") },
          { validator: validateTenantID },
          {
            pattern: /^[A-Za-z]+$/,
            message: t("register.onlyEnglishAlphabet"),
          },
        ]}
      >
        <Input
          prefix={<span className="tenant-prefix">trybookly.net.</span>}
          suffix={
            availabilityMessage && (
              <span
                className={`tenant-suffix ${
                  availabilityMessage === t("register.tenantIdTaken")
                    ? "error"
                    : "success"
                }`}
              >
                {availabilityMessage}
              </span>
            )
          }
          placeholder={t("register.tenantID")}
          onChange={onTenantIDChange}
        />
      </Form.Item>
      <Form.Item
        name="businessTypeId"
        rules={[{ required: true, message: t("register.selectType") }]}
      >
        <Select placeholder={t("register.selectType")} size="large">
          {typeList.map((type) => (
            <Select.Option key={type.id} value={type.id}>
              {type.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading} block>
          {t("register.registerButton")}
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <Row justify="center" align="middle">
      <Col>
        <div className="register-form-container">
          <div className="register-form">
            <img src={icon} alt="icon" className="icon" />
            <h1>{t("register.title")}</h1>
            {step === 1 && renderStep1()}
            {step === 2 && renderStep2()}
            {step === 3 && <OTPForm email={email} tenantId={tenantId} />}
            <div className="separator">
              <span className="separator-text">{t("register.or")}</span>
            </div>
            <div className="create-account">
              <span>
                {t("register.haveAccount")}
                <a href="/login" style={{ margin: "3px" }}>
                  {t("register.logIn")}
                </a>
              </span>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default RegisterForm;
