import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Modal } from "antd";
import Meeting from "./Meeting";
import DetailModal from "./DetailModal";
import "./NoticeCalendar.module.css";
import CustomToolbar from "../Availability/CustomToolbar";
import { getMonthlyAvailabilities } from "../../../services/api";
import styled from "styled-components";

const NoticeCalendar = ({ meetings, onSlotSelect }) => {
  moment.locale("en-gb"); // Set the locale to English (UK)

  const localizer = momentLocalizer(moment);
  const [moreEventsModalVisible, setMoreEventsModalVisible] = useState(false);
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [moreEventsModalContent, setMoreEventsModalContent] = useState([]);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [detailModalPosition, setDetailModalPosition] = useState({
    top: 0,
    left: 0,
  });
  const [mousePosition, setMousePosition] = useState({ top: 500, left: 500 });
  const [availabilities, setAvailabilities] = useState([]);
  const [currentYear, setCurrentYear] = useState(moment().year());
  const [currentMonth, setCurrentMonth] = useState(moment().month() + 1); // month is 0-indexed
  const jwtToken = localStorage.getItem("token");

  const fetchMonthlyAvailabilities = async (year, month) => {
    const data = { year, month };
    const availabilities = await getMonthlyAvailabilities(data, jwtToken);
    setAvailabilities(availabilities);
  };

  useEffect(() => {
    fetchMonthlyAvailabilities(currentYear, currentMonth);
  }, [currentYear, currentMonth]);
  const EventTitle = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    direction: rtl;
  `;
  const events = meetings.map((meeting) => ({
    title: (
      <EventTitle>
        <span>{meeting.user?.firstName}</span>
        <span>{meeting.startTime.substring(0, 5)}</span>
      </EventTitle>
    ),
    start: new Date(meeting.bookingDate),
    end: new Date(meeting.bookingDate),
    ...meeting,
  }));

  const handleShowMore = (events, date) => {
    setMoreEventsModalContent(events);
    setMoreEventsModalVisible(true);
  };

  const handleMoreEventsModalClose = () => {
    setMoreEventsModalVisible(false);
  };

  const handleDetailModalClose = () => {
    setDetailModalVisible(false);
  };

  const handleMeetingClick = (meeting, e) => {
    setSelectedMeeting(meeting);
    setDetailModalPosition(getAdjustedPosition(e.clientY, e.clientX, "detail"));
    setDetailModalVisible(true);
  };

  const handleSelectEvent = (event, e) => {
    handleMeetingClick(event, e);
  };

  const handleSelectSlot = ({ start }) => {
    const availability = availabilities.find(
      (availability) =>
        moment(availability.date).format("YYYY-MM-DD") ===
        moment(start).format("YYYY-MM-DD")
    );
    if (availability && !availability.isOff) {
      onSlotSelect(start);
    } else {
      console.log("Cannot add appointment on this date:", start);
    }
  };

  const handleNavigate = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    setCurrentYear(year);
    setCurrentMonth(month);
    fetchMonthlyAvailabilities(year, month);
  };

  const getAdjustedPosition = (top, left, modalType) => {
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;
    const modalHeight = modalType === "more" ? 180 : 200; // Adjust based on actual modal height
    const modalWidth = modalType === "more" ? 300 : 400; // Adjust based on actual modal width

    if (top + modalHeight > viewportHeight - 40) {
      top = viewportHeight - modalHeight - 100; // 20px margin from bottom
    }
    if (left + modalWidth > viewportWidth) {
      left = viewportWidth - modalWidth - 40; // 20px margin from right
    }
    return { top, left };
  };

  const eventPropGetter = (event) => {
    const backgroundColor = event.service.color
      ? `rgba(${event.service.color}, 0.3)`
      : "#3174ad";
    const borderColor = event.service.color
      ? `rgba(${event.service.color})`
      : "#3174ad";
    return {
      style: {
        backgroundColor,
        borderRight: `3px solid ${borderColor}`,
        borderRadius: "0px",
        opacity: 0.8,
        color: "black",
      },
    };
  };

  const dateCellWrapper = ({ value }) => {
    const formattedDate = moment(value).format("YYYY-MM-DD");
    const availability = availabilities.find(
      (availability) =>
        moment(availability.date).format("YYYY-MM-DD") === formattedDate
    );

    let style = {};
    if (availability && availability.isOff) {
      style = { backgroundColor: "#eee" }; // Light gray for off days
    } else if (availability && !availability.isOff) {
      style = { backgroundColor: "#fff", cursor: "pointer" }; // White for available days
    } else {
      style = { backgroundColor: "#eee" }; // Light gray for unavailable days
    }

    return (
      <div
        style={{
          ...style,
          height: "100%",
          width: "100%",
        }}
      ></div>
    );
  };

  const customDayPropGetter = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    const availability = availabilities.find(
      (availability) =>
        moment(availability.date).format("YYYY-MM-DD") === formattedDate
    );

    if (availability && availability.isOff) {
      return {
        className: "off-day",
      };
    } else if (availability && !availability.isOff) {
      return {
        className: "available-day",
      };
    } else {
      return {
        className: "unavailable-day",
      };
    }
  };

  return (
    <div
      style={{ direction: "ltr", height: "500px" }}
      onClick={(e) => setMousePosition({ top: e.clientY, left: e.clientX })}
    >
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        views={["month"]}
        defaultView="month"
        style={{ height: "100%" }}
        selectable
        onSelectEvent={(event, e) => handleSelectEvent(event, e)}
        onShowMore={(events, date) => handleShowMore(events, date)}
        onSelectSlot={handleSelectSlot}
        onNavigate={handleNavigate}
        eventPropGetter={eventPropGetter}
        dayPropGetter={customDayPropGetter}
        components={{
          toolbar: CustomToolbar,
          dateCellWrapper,
        }}
      />
      <Modal
        title="المواعيد"
        open={moreEventsModalVisible}
        onCancel={handleMoreEventsModalClose}
        footer={null}
        style={{ direction: "rtl" }}
        width={300}
      >
        {moreEventsModalContent.map((meeting, index) => (
          <Meeting
            key={index}
            meeting={meeting}
            onClick={(e) => handleMeetingClick(meeting, e)}
          />
        ))}
      </Modal>
      <DetailModal
        isVisible={detailModalVisible}
        onClose={handleDetailModalClose}
        selectedMeeting={selectedMeeting}
        modalPosition={detailModalPosition}
      />
    </div>
  );
};

export default NoticeCalendar;
