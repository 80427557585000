import React, { useEffect } from "react";
import { Modal, Form, Input, Button, Select } from "antd";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const AddServiceModal = ({
  visible,
  onCancel,
  onFinish,
  categories,
  initialValues,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  const handleFinish = (values) => {
    const color = values.color;
    const rgb = hexToRgb(color);
    onFinish({ ...values, color: rgb });
    form.resetFields();
  };

  const hexToRgb = (hex) => {
    let bigint = parseInt(hex.replace("#", ""), 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;
    return `${r}, ${g}, ${b}`;
  };

  return (
    <Modal
      style={{ direction: "rtl" }}
      open={visible}
      title={t("addService.title")}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          {t("addService.cancelButton")}
        </Button>,
        <Button key="submit" type="primary" onClick={() => form.submit()}>
          {t("addService.addButton")}
        </Button>,
      ]}
      width={400}
    >
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <Form.Item
          name="name"
          label={t("addService.nameLabel")}
          rules={[{ required: true, message: t("addService.nameRequired") }]}
          style={{ width: 150 }}
        >
          <Input />
        </Form.Item>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="description"
            label={t("addService.descriptionLabel")}
            rules={[
              { required: true, message: t("addService.descriptionRequired") },
            ]}
            style={{ width: 200 }}
          >
            <Input.TextArea style={{ height: 30 }} />
          </Form.Item>

          <Form.Item
            name="categoryId"
            label={t("addService.categoryLabel")}
            rules={[
              { required: true, message: t("addService.categoryRequired") },
            ]}
            style={{ width: 150 }}
          >
            <Select placeholder={t("addService.categoryPlaceholder")}>
              {categories.map((category) => (
                <Option key={category.id} value={category.id}>
                  {category.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Form.Item
            name="color"
            label={t("addService.colorLabel")}
            rules={[{ required: true, message: t("addService.colorRequired") }]}
            style={{ width: 100 }}
          >
            <Input type="color" />
          </Form.Item>
          <Form.Item
            name="duration"
            label={t("addService.durationLabel")}
            rules={[
              { required: true, message: t("addService.durationRequired") },
            ]}
            style={{ width: 100 }}
          >
            <Select>
              <Option value={15}>15 دقيقة</Option>
              <Option value={30}>30 دقيقة</Option>
              <Option value={45}>45 دقيقة</Option>
              <Option value={60}>60 دقيقة</Option>
              <Option value={90}>90 دقيقة</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="price"
            label={t("addService.priceLabel")}
            rules={[{ required: true, message: t("addService.priceRequired") }]}
            style={{ width: 100 }}
          >
            <Input type="number" />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default AddServiceModal;
