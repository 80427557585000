import React from "react";
import {
  UserOutlined,
  ClockCircleOutlined,
  MailOutlined,
  CarryOutOutlined,
} from "@ant-design/icons";
import CalculateFormattedEndTime from "../../../shared/calculateFormattedEndTime";

function AppointmentDetails({ appointmentDetails }) {
  // const { bookingDate, startTime, endTime, servive, user } = appointmentDetails;
  const formattedTime = CalculateFormattedEndTime(
    appointmentDetails.startTime,
    appointmentDetails.endTime
  );

  const serviceColor = appointmentDetails.service.color;
  const serviceName = appointmentDetails.service.name;
  const serviceDuration = appointmentDetails.service.duration;
  console.log("appointmentDetails ------------>", appointmentDetails);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "right",
        gap: "12px",
        maxHeight: "400px",
        overflowY: "auto",
        fontSize: "16px",
        padding: "12px 27px",
        color: "#1A1F23",
      }}
    >
      <div
        style={{
          padding: "8px",
          borderTop: `3px solid rgba(${serviceColor})`,
          background: `linear-gradient(rgba(${serviceColor}, 0.25), rgba(${serviceColor}, 0.25)),linear-gradient(white, white)`,
        }}
      >
        {serviceName}
      </div>

      <div className="marg-padd">
        <CarryOutOutlined className="appo-detail-icon " />
        {appointmentDetails.bookingDate.substring(0, 10)}
      </div>

      <div>
        <ClockCircleOutlined className="appo-detail-icon" />
        {formattedTime.start}
        <span style={{ margin: "0 6px" }}>-</span>
        {formattedTime.end}
        <span style={{ margin: "0px 40px 0 0" }}>{serviceDuration} دقيقة</span>
      </div>

      <div className="marg-padd">
        <UserOutlined className="appo-detail-icon" />
        {appointmentDetails.user.firstName}
      </div>

      <div className="marg-padd">
        <MailOutlined className="appo-detail-icon" />
        {appointmentDetails.user.email}
      </div>
    </div>
  );
}

export default AppointmentDetails;
