import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./AvailabilityCalendar.css";
import styles from "./availabilityCalendar.module.css";
import { Modal, Form, Input, Button, notification } from "antd";
import CustomToolbar from "./CustomToolbar";

const AvailabilityCalendar = ({
  events,
  onEditEvent,
  onDeleteEvent,
  handleNavigate,
}) => {
  moment.locale("en-gb"); // Set the locale to English (UK)
  const localizer = momentLocalizer(moment);

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [form] = Form.useForm(); // Create a form instance

  const formattedEvents = events.map((event) => {
    const start = event.startTime.substring(0, 5);
    const end = event.endTime.substring(0, 5);
    const title = event.isOff ? "غير متاح" : `${start} - ${end}`;

    return {
      ...event,
      start: new Date(event.date),
      end: new Date(event.date),
      title: title,
      isPast: moment(event.date).isBefore(moment(), "day"),
    };
  });

  const eventPropGetter = (event) => {
    if (event.isPast) {
      return { className: `${styles.eventCustom} ${styles.pastEvent}` };
    }
    return { className: styles.eventCustom };
  };

  const dayPropGetter = (date) => {
    const hasEvent = formattedEvents.some((event) =>
      moment(event.start).isSame(date, "day")
    );
    const isPast = moment(date).isBefore(moment(), "day");

    if (hasEvent) {
      return { className: `${styles.dayBg} ${styles.dayWithEvent}` };
    }
    if (isPast) {
      return { className: `${styles.dayBg} ${styles.pastDay}` };
    }
    if (!hasEvent) {
      if (moment(date).isSame(moment(), "day")) {
        return { className: `${styles.dayBg} ${styles.todayOffRange}` };
      }
      return { className: `${styles.dayBg} ${styles.dayBgOffRange}` };
    }
    return {};
  };

  const handleSelectEvent = (event) => {
    if (moment(event.date).isBefore(moment(), "day")) {
      return; // Prevent editing past events
    }

    const selected = {
      ...event,
      startTime: event.startTime.substring(0, 5),
      endTime: event.endTime.substring(0, 5),
      date: moment(event.date),
    };

    setSelectedEvent(selected);
    form.setFieldsValue(selected); // Update form values
    setIsEditModalVisible(true);
  };

  const handleTimeChange = (e, timeType) => {
    const time = e.target.value;
    setSelectedEvent((prevEvent) => ({
      ...prevEvent,
      [timeType]: time,
    }));
  };

  const handleBlur = (e, timeType) => {
    const time = e.target.value;
    const timePattern = /^([01]\d|2[0-3]):([0-5]\d)$/;

    if (time === "" || timePattern.test(time)) {
      setSelectedEvent((prevEvent) => ({
        ...prevEvent,
        [timeType]: time || "00:00",
      }));
    } else {
      notification.error({
        message: "Invalid Time Format",
        description: "Please enter the time in HH:mm format.",
      });
      setSelectedEvent((prevEvent) => ({
        ...prevEvent,
        [timeType]: "00:00",
      }));
    }
  };

  const handleEditSubmit = () => {
    if (
      selectedEvent.endTime < selectedEvent.startTime &&
      !(
        selectedEvent.endTime === "00:00" && selectedEvent.startTime === "00:00"
      )
    ) {
      notification.error({
        message: "Invalid Time",
        description: "End time cannot be earlier than start time.",
      });
      return;
    } else if (
      selectedEvent.endTime === selectedEvent.startTime &&
      selectedEvent.endTime !== "00:00"
    ) {
      notification.error({
        message: "Invalid Time",
        description: "End time cannot be equal to start time.",
      });
      return;
    }

    const updatedEvent = {
      startTime: `${selectedEvent.startTime}:00`,
      endTime: `${selectedEvent.endTime}:00`,
      date: selectedEvent.date.format("YYYY-MM-DD"),
    };
    onEditEvent(updatedEvent);
    setIsEditModalVisible(false);
  };

  const handleDeleteEvent = () => {
    if (moment(selectedEvent.date).isBefore(moment(), "day")) {
      return; // Prevent deleting past events
    }
    onDeleteEvent(selectedEvent);
    setIsEditModalVisible(false);
  };

  return (
    <div className={styles.calendarContainer}>
      <Calendar
        localizer={localizer}
        events={formattedEvents}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        views={["month"]}
        defaultView="month"
        showMultiDayTimes
        selectable
        eventPropGetter={eventPropGetter}
        dayPropGetter={dayPropGetter}
        onSelectEvent={handleSelectEvent}
        onNavigate={handleNavigate}
        components={{ toolbar: CustomToolbar }}
      />

      <Modal
        open={isEditModalVisible}
        title="Edit Event"
        onCancel={() => setIsEditModalVisible(false)}
        footer={null}
        width={340}
        // style={{ direction: "rtl" }}
      >
        {selectedEvent && (
          <Form
            form={form} // Use the form instance
            initialValues={{
              startTime: selectedEvent.startTime,
              endTime: selectedEvent.endTime,
              date: selectedEvent.date,
            }}
            onFinish={handleEditSubmit}
          >
            <Form.Item
              name="startTime"
              label="Start Time"
              rules={[{ required: true, message: "Please select start time" }]}
            >
              <Input
                value={selectedEvent.startTime}
                onChange={(e) => handleTimeChange(e, "startTime")}
                onBlur={(e) => handleBlur(e, "startTime")}
                placeholder="HH:mm"
                style={{ marginBottom: 8, textAlign: "center", width: "auto" }}
              />
            </Form.Item>
            <Form.Item
              name="endTime"
              label="End Time"
              rules={[{ required: true, message: "Please select end time" }]}
            >
              <Input
                value={selectedEvent.endTime}
                onChange={(e) => handleTimeChange(e, "endTime")}
                onBlur={(e) => handleBlur(e, "endTime")}
                placeholder="HH:mm"
                style={{
                  margin: "0 0 8 5",
                  textAlign: "center",
                  width: "auto",
                }}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
              <Button
                type="danger"
                onClick={handleDeleteEvent}
                style={{ marginLeft: 8 }}
              >
                Delete
              </Button>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </div>
  );
};

export default AvailabilityCalendar;
