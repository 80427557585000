import React from "react";
import { Col, Row, Empty } from "antd";
import moment from "moment";
import CardAppointment from "./CardAppointment";

function CardMetting({ meetings }) {
  const today = moment().startOf("day");

  const upcomingAppointments = meetings
    .filter((meeting) => moment(meeting.date).isSameOrAfter(today))
    .sort((a, b) => moment(a.date) - moment(b.date))
    .slice(0, 3);

  if (upcomingAppointments.length === 0) {
    return (
      <Row justify="start">
        <Col>
          <Empty
            description="لا توجد حجوزات قادمة"
            imageStyle={{
              height: 50,
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
            }}
          />
        </Col>
      </Row>
    );
  }

  return (
    <Row gutter={16} justify="start" align="top">
      {upcomingAppointments.map((meeting, index) => (
        <Col key={index} span={8}>
          <CardAppointment appointmentDetails={meeting} />
        </Col>
      ))}
    </Row>
  );
}

export default CardMetting;
