import React, { useState, useEffect } from "react";
import CardMetting from "./CardMetting";
import { jwtDecode } from "jwt-decode";
import { Button, message } from "antd";
import NoticeCalendar from "./NoticeCalendar";
import MainModal from "./MainModal";
import NewAppointment from "./NewAppointment"; // Import the NewAppointment component
import { useTranslation } from "react-i18next";
import { getBookings } from "../../../services/api";

function Calendar() {
  const [meetings, setMeetings] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [newAppointmentVisible, setNewAppointmentVisible] = useState(false);
  const [modalContent, setModalContent] = useState([]);
  const [modalPosition, setModalPosition] = useState({
    top: 0,
    left: 0,
    right: "auto",
  });
  const [selectedDate, setSelectedDate] = useState(null); // Add state for selected date
  const [tenantID, setTenantID] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    const jwtToken = localStorage.getItem("token");
    if (jwtToken) {
      const decodedToken = jwtDecode(jwtToken);
      setTenantID(decodedToken.TenantId);
    }
    fetchMeetingsData();
  }, []);

  const fetchMeetingsData = async () => {
    try {
      const jwtToken = localStorage.getItem("token");
      const meetings = await getBookings(jwtToken);
      setMeetings(meetings);
    } catch (error) {
      console.error("Error fetching meetings:", error);
    }
  };

  const handleDeleteAppointment = async (id) => {
    setMeetings((meetings) => meetings.filter((meeting) => meeting.id !== id));
  };

  const handleUpdateAppointment = async (id, updatedMeeting) => {
    setMeetings((meetings) =>
      meetings.map((meeting) => (meeting.id === id ? updatedMeeting : meeting))
    );
  };

  const handleCreateAppointment = (newMeeting) => {
    fetchMeetingsData();
  };

  const handleMeetingClick = (meeting, event) => {
    console.log("Meeting clicked:", meeting);
    setModalContent(meeting.events);
    setModalPosition({
      top: event.clientY,
      left: event.clientX,
      right: "auto",
    });
    setModalVisible(true);
  };

  const handleShowMore = (events, date, event) => {
    setModalContent(events);
    setModalPosition({
      top: event.clientY,
      left: event.clientX,
      right: "auto",
    });
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleSlotSelect = (slotInfo) => {
    setSelectedDate(slotInfo);

    setNewAppointmentVisible(true);
  };

  const handleNewAppointmentClose = () => {
    setNewAppointmentVisible(false);
  };

  const handleCopyLink = () => {
    const link = `${tenantID}.trybookly.net`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        message.success(t("linkCopied"));
      })
      .catch((err) => {
        message.error(t("linkCopyFailed"));
        console.error("Failed to copy the link: ", err);
      });
  };

  return (
    <div style={{ margin: "20px" }}>
      <div>
        <h3 style={{ margin: "5px 7px 15px 0px" }}>
          {t("upcomingReservations")}
        </h3>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "85% 15%",
            margin: "5px",
          }}
        >
          <CardMetting meetings={meetings} />
          <div style={{ direction: "ltr" }}>
            <Button className="copy-btn" onClick={handleCopyLink}>
              {t("copyThePageLink")}
            </Button>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "15px" }}>
        <NoticeCalendar
          meetings={meetings}
          handleShowMore={handleShowMore}
          handleMeetingClick={handleMeetingClick}
          onSlotSelect={handleSlotSelect} // Pass the handleSlotSelect function
        />
      </div>
      <MainModal
        isVisible={modalVisible}
        onClose={handleModalClose}
        modalContent={modalContent}
        handleMeetingClick={handleMeetingClick}
        modalPosition={modalPosition}
      />
      <NewAppointment
        visible={newAppointmentVisible}
        onClose={handleNewAppointmentClose}
        onSubmit={handleNewAppointmentClose}
        handleCreateAppointment={handleCreateAppointment}
        handleUpdateAppointment={handleUpdateAppointment}
        handleDeleteAppointment={handleDeleteAppointment}
        selectedDate={selectedDate} // Pass the selectedDate prop
        tenantID={tenantID}
      />
    </div>
  );
}

export default Calendar;
