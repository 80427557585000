import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, notification } from "antd";
import { verifyOtp } from "../../services/api";
import OTPInput from "./OTPInput";
import { useTranslation } from "react-i18next";

const OTPForm = ({ email, tenantId }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const { t } = useTranslation();
  const otpLength = 4;

  const onFinish = async () => {
    setLoading(true);
    try {
      const data = {
        email,
        otp,
        tenantId,
      };
      const response = await verifyOtp(data);
      notification.success({
        message: t("register.success"),
        description: t("register.successDescription"),
      });
      navigate("/adminpage");
    } catch (error) {
      notification.error({ message: "Invalid OTP" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form onFinish={onFinish} size="large">
      <OTPInput length={otpLength} onChange={setOtp} />
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={loading}
          disabled={otp.length !== otpLength}
        >
          تحقق
        </Button>
      </Form.Item>
    </Form>
  );
};

export default OTPForm;
