export const CalculateFormattedTimes = (startTime, endTime) => {
  const parseTime = (time) => {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    const date = new Date();
    date.setHours(hours, minutes, seconds, 0);
    return date;
  };

  const formatTime = (date) => {
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "م" : "ص";
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    const formattedHours = hours.toString().padStart(2, "0");
    return `${formattedHours}:${minutes} ${ampm}`;
  };

  const start = parseTime(startTime);
  const end = parseTime(endTime);

  const formattedStartTime = formatTime(start);
  const formattedEndTime = formatTime(end);

  return {
    start: formattedStartTime,
    end: formattedEndTime,
  };
};

export default CalculateFormattedTimes;
