import React, { useState } from "react";
import { Form, Input, Button, Row, Col, notification } from "antd";
import {
  UserOutlined,
  LockOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { login } from "../../services/api";
import icon from "../../assets/icon.svg";
import PropTypes from "prop-types";
import "./Login.css";

const LoginForm = ({ onLoginSuccess }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setLoading(true);
    console.log("Received values:", values);
    try {
      const response = await login(values);
      console.log("response -----> ", response);
      notification.success({
        message: t("login.success"),
      });
      if (onLoginSuccess && response.token) {
        onLoginSuccess(response.token);
      } else {
        throw new Error("Token is missing from the response");
      }
    } catch (error) {
      console.error("Login failed:", error);
      notification.error({
        message: error.response?.statusText || t("login.error"),
        description: error.message || t("login.errorDescription"),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row justify="center" align="middle">
      <Col>
        <div className="login-form">
          <img src={icon} alt="icon" className="icon" />
          <h1>{t("login.title")}</h1>
          <Form
            form={form}
            name="loginForm"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            size="large"
          >
            <Form.Item
              name="email"
              rules={[
                { required: true, message: t("login.emailRequired") },
                { type: "email", message: t("login.emailInvalid") },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder={t("login.emailPlaceholder")}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: t("login.passwordRequired") }]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder={t("login.passwordPlaceholder")}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            {/* <div
              style={{ textAlign: "left", margin: "-8px 0 0 0", color: "#000" }}
            >
              <a href="/forgot-password" className="forgot-password-link">
                {t("login.forgotPassword")}
              </a>
            </div> */}
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading} block>
                {t("login.loginButton")}
              </Button>
            </Form.Item>
          </Form>
          <div className="separator" style={{ margin: "20px 0 " }}>
            <span className="separator-text">{t("register.or")}</span>
          </div>
          <div className="create-account">
            <span>
              {t("login.Don't have an account?")}
              <a href="/register" style={{ margin: "3px" }}>
                {t("login.Sign up")}
              </a>
            </span>
          </div>
        </div>
      </Col>
    </Row>
  );
};

LoginForm.propTypes = {
  onLoginSuccess: PropTypes.func.isRequired,
};

export default LoginForm;
