import React from "react";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const PrivateRoute = ({ element, ...rest }) => {
  const token = localStorage.getItem("token"); // Assuming the token is stored in local storage

  if (!token) {
    return <Navigate to="/login" />;
  }

  try {
    const decoded = jwtDecode(token);
    console.log("Decoded token:", decoded);

    // Access the role claim using the correct key
    const role =
      decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
    console.log("User role:", role);

    if (role !== "Admin") {
      return <Navigate to="/unauthorized" />;
    }
  } catch (error) {
    console.error("Invalid token", error);
    return <Navigate to="/login" />;
  }

  return element;
};

export default PrivateRoute;
