import React from "react";
import { Modal, Form, Input, Button } from "antd";
import { useTranslation } from "react-i18next";

const AddCategoryModal = ({ visible, onCancel, onFinish }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  return (
    <Modal
      style={{ direction: "rtl" }}
      title={t("addCategory.title")}
      open={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          {t("addCategory.cancel")}
        </Button>,
        <Button key="submit" type="primary" onClick={() => form.submit()}>
          {t("addCategory.submit")}
        </Button>,
      ]}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          name="name"
          label={t("addCategory.nameLabel")}
          rules={[{ required: true, message: t("addCategory.nameRequired") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label={t("addCategory.descriptionLabel")}
          rules={[
            { required: true, message: t("addCategory.descriptionRequired") },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddCategoryModal;
