import { Button, Layout } from "antd";
import Logo from "./Logo";
import "./AdminPage.css";
import MenuList from "./MenuList";
import { useState } from "react";
import ToggleThemeButton from "./ToggleThemeButton";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Routes, Route, useNavigate } from "react-router-dom";
import Availability from "./Availability/Availability";
import Calendar from "./Calendar/Calendar";
import CategoryServicePage from "./Services/CategoryServicePage";
import Clients from "./Client/Clients";
import icon from "../../assets/icon.svg";

const { Header, Sider, Content } = Layout;

function AdminPage() {
  const [darkTheme, setDarkTheme] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();

  // const toggleTheme = () => {
  //   setDarkTheme(!darkTheme);
  // };

  const handleMenuClick = (e) => {
    navigate(`/adminPage/${e.key}`);
  };

  return (
    <Layout className="layout" style={{ direction: "rtl" }}>
      <Sider
        collapsed={collapsed}
        className="sidebar"
        theme={darkTheme ? "light" : "light"}
      >
        <img src={icon} alt="icon" className="iconAdmin" />
        <MenuList darkTheme={darkTheme} handleMenuClick={handleMenuClick} />
        {/* <ToggleThemeButton darkTheme={darkTheme} toggleTheme={toggleTheme} /> */}
      </Sider>

      <Layout>
        <Header style={{ padding: 0, backgroundColor: "inherit" }}>
          <Button
            type="text"
            onClick={() => setCollapsed(!collapsed)}
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          />
        </Header>
        <Content className="content">
          <Page />
        </Content>
      </Layout>
    </Layout>
  );
}

function Page() {
  return (
    <Routes>
      <Route path="calendar" element={<Calendar />} />
      <Route path="availability" element={<Availability />} />
      <Route path="services" element={<CategoryServicePage />} />
      <Route path="clients" element={<Clients />} />
    </Routes>
  );
}

export default AdminPage;
