import React, { useState } from "react";
import { Button, Popconfirm, message } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { deleteService, updateService } from "../../../services/api";
import AddServiceModal from "./AddServiceModal";

const numberToArabicWords = (num) => {
  const arabicNumbers = [
    "صفر",
    "واحد",
    "اثنان",
    "ثلاثة",
    "أربعة",
    "خمسة",
    "ستة",
    "سبعة",
    "ثمانية",
    "تسعة",
  ];
  const tens = [
    "",
    "عشر",
    "عشرون",
    "ثلاثون",
    "أربعون",
    "خمسون",
    "ستون",
    "سبعون",
    "ثمانون",
    "تسعون",
  ];
  const hundreds = [
    "",
    "مائة",
    "مائتان",
    "ثلاثمائة",
    "أربعمائة",
    "خمسمائة",
    "ستمائة",
    "سبعمائة",
    "ثمانمائة",
    "تسعمائة",
  ];

  if (num < 10) {
    return arabicNumbers[num];
  } else if (num < 100) {
    const unit = num % 10;
    const ten = Math.floor(num / 10);
    return `${unit > 0 ? arabicNumbers[unit] + " و" : ""}${tens[ten]}`;
  } else {
    const remainder = num % 100;
    const hundred = Math.floor(num / 100);
    return `${hundreds[hundred]}${
      remainder > 0 ? " و" + numberToArabicWords(remainder) : ""
    }`;
  }
};

const Service = ({ service, onDelete }) => {
  const { t } = useTranslation();
  const jwtToken = localStorage.getItem("token");
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const handleDelete = async () => {
    try {
      await deleteService(service.id, jwtToken);
      message.success(t("service.deleteSuccess"));
      if (onDelete) {
        onDelete(service.id);
      }
      window.location.reload();
    } catch (error) {
      message.error(t("service.deleteFailed"));
      console.error("Error deleting service:", error);
    }
  };

  const handleEdit = async (updatedService) => {
    try {
      await updateService(service.id, updatedService, jwtToken);
      message.success(t("service.updateSuccess"));
      setIsEditModalVisible(false);
      window.location.reload();
    } catch (error) {
      message.error(t("service.updateFailed"));
      console.error("Error updating service:", error);
    }
  };

  return (
    <div
      key={service.id}
      style={{
        direction: "rtl",
        fontSize: "16px",
        padding: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "40%",
          }}
        >
          <div
            style={{
              background: `rgba(${service.color} , 0.33 )`,
              width: "20px",
              height: "20px",
              borderRadius: "50%",
              marginLeft: "10px",
            }}
          ></div>
          <div>{service.name}</div>
        </div>
        <div style={{ width: "20%", textAlign: "center" }}>
          {`${service.price} ريال`}
        </div>
        <div style={{ width: "20%", textAlign: "center" }}>
          {`${numberToArabicWords(service.duration)} دقيقة`}
        </div>
        <div style={{ width: "20%", textAlign: "center" }}>
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={() => setIsEditModalVisible(true)}
            style={{
              border: "none",
              background: "transparent",
              boxShadow: "none",
              padding: 4,
              marginRight: 8,
            }}
          />
          <Popconfirm
            title={t("service.deleteConfirm")}
            onConfirm={handleDelete}
            okText={t("Yes")}
            cancelText={t("No")}
          >
            <Button
              type="text"
              icon={<DeleteOutlined />}
              danger
              style={{
                border: "none",
                background: "transparent",
                boxShadow: "none",
                padding: 4,
              }}
            />
          </Popconfirm>
        </div>
      </div>

      <AddServiceModal
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        onFinish={handleEdit}
        categories={[]}
        initialValues={service}
      />
    </div>
  );
};

export default Service;
