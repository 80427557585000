import React from "react";
import { Modal } from "antd";
import AppointmentDetails from "./AppointmentDetails"; // Ensure this component exists

const DetailModal = ({
  isVisible,
  onClose,
  selectedMeeting,
  modalPosition,
}) => (
  <Modal
    title="تفاصيل الموعد"
    open={isVisible}
    onCancel={onClose}
    footer={null}
    style={{
      top: modalPosition.top,
      left: modalPosition.left,
      right: modalPosition.right,
      position: "absolute",
      direction: "rtl",
      zIndex: 1100,
    }}
    className="detail-modal"
  >
    {selectedMeeting && (
      <AppointmentDetails appointmentDetails={selectedMeeting} />
    )}
  </Modal>
);

export default DetailModal;
