import "./App.css";
import AdminPage from "./components/AdminPage/AdminPage";
import PrivateRoute from "./components/loginPage/PrivateRoute";
import Login from "./components/loginPage/Login";
import Unauthorized from "./components/loginPage/Unauthorized";
import Register from "./components/loginPage/Register";
import Landing from "./components/landingPage/landing";
import TermsAndConditions from "./components/landingPage/TermsAndConditions";

import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <Routes>
      <Route
        path="/adminPage/*"
        element={<PrivateRoute element={<AdminPage />} />}
      />
      <Route path="/" element={<Landing />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
    </Routes>
  );
}

export default App;
