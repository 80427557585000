import React from "react";
import CalculateFormattedEndTime from "../../../shared/calculateFormattedEndTime";

function CardAppointment({ appointmentDetails }) {
  // const startDate = new Date(appointmentDetails.time);
  console.log("CardAppointment ", appointmentDetails);
  // const { start: startTime, end: endTime } = CalculateFormattedEndTime(
  //   appointmentDetails?.startTime,
  //   appointmentDetails?.endTime
  // );

  return (
    <div className="card-appointment">
      <div className="card-upper-part">
        <p>{`${appointmentDetails.startTime} - ${appointmentDetails.endTime}`}</p>
        <p>{appointmentDetails.bookingDate?.substring(0, 10)}</p>
      </div>
      <div className="card-middle-lower-part">
        <div className="card-middle-part">
          <p>{appointmentDetails.user?.firstName}</p>
          <p>{appointmentDetails.service?.name}</p>
        </div>
        <div className="card-lower-part">
          <p>{appointmentDetails.user?.phoneNumber}</p>
          <p>{appointmentDetails.user?.email}</p>
        </div>
      </div>
    </div>
  );
}

export default CardAppointment;
