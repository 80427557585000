import React, { useEffect } from "react";
import LoginForm from "./LoginForm";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import { ReactComponent as DarkBoxSvg } from "../../assets/DarkBox.svg";
import { ReactComponent as LightBoxSvg } from "../../assets/LightBox.svg";

const Login = () => {
  const navigate = useNavigate();

  const handleLoginSuccess = (token) => {
    localStorage.setItem("token", token);
    navigate("/adminPage/calendar");
  };

  return (
    <div className="Login">
      <div className="custom-box">
        <LoginForm onLoginSuccess={handleLoginSuccess} />
      </div>
      <div className="top-left-dark">
        <DarkBoxSvg />
      </div>
      <div className="top-left-light">
        <LightBoxSvg />
      </div>
      <div className="bottom-right-dark">
        <DarkBoxSvg />
      </div>
      <div className="bottom-right-light">
        <LightBoxSvg />
      </div>
    </div>
  );
};

export default Login;
